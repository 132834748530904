import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSmile } from 'react-icons/fa';
import { BASE_URL, token } from '../../config';

function OrderPlaceSuccess() {
  const navigate = useNavigate();
  // clear cart
  const handleNavigate = async () => {
    try {
      const response = await fetch(`${BASE_URL}/cart`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to clear cart');
      }
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
    navigate('/my-account');
  };

  return (
    <div className='container mx-auto flex flex-col justify-center items-center h-screen text-center'>
      <div className='bg-gray-200 p-8 rounded-lg flex flex-col items-center justify-center shadow-lg shadow-gray-400'>
        <h1 className='text-4xl font-bold mb-4'>Order Confirmed</h1>
        <FaSmile className='text-8xl text-green-500 mb-8' />
        <p className='text-xl mb-8'>Thank you for your purchase.</p>
        <button
          className='bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 rounded'
          onClick={handleNavigate}
        >
          Back To Home
        </button>
      </div>
    </div>
  );
}

export default OrderPlaceSuccess;
