import React, { useState, useEffect } from 'react';
import FetchData from '../../hooks/fetchData';
import { BASE_URL, token } from '../../config';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cart = location.state?.cart || [];
  const [paymentMethod, setPaymentMethod] = useState('bank');

  // Handle payment method
  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  // Calculate totals
  const subtotal = cart.reduce(
    (total, item) => total + item.product.price * item.quantity,
    0
  );
  const shipping = 0;
  const total = subtotal + shipping;

  const { data: user } = FetchData(`${BASE_URL}/users/profile`);

  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    address: {
      street: user?.address?.street || '',
      city: user?.address?.city || '',
      state: user?.address?.state || '',
      zipCode: user?.address?.zipCode || '',
      country: user?.address?.country || '',
    },
  });

  // FormData state
  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        address: {
          street: user.address?.street || '',
          city: user.address?.city || '',
          state: user.address?.state || '',
          zipCode: user.address?.zipCode || '',
          country: user.address?.country || '',
        },
      });
    }
  }, [user]);

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('address')) {
      const fieldName = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Submit order
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate required fields
      const { name, email, phone, address } = formData;
      if (
        !name ||
        !email ||
        !phone ||
        !address.street ||
        !address.city ||
        !address.state ||
        !address.zipCode ||
        !address.country
      ) {
        throw new Error('Please fill in all required fields');
      }

      // Prepare products data for the backend
      const products = cart.map((item) => ({
        id: item.product._id,
        name: item.product.name,
        price: item.product.price,
        images: item.product.images,
        quantity: item.quantity,
      }));

      // Place order and clear cart
      if (paymentMethod === 'bank') {
        const orderResponse = await fetch(
          `${BASE_URL}/orders/checkout-session`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              products,
              totalQuantity: cart.reduce((total, item) => total + item.quantity, 0),
              totalAmount: total,
              shippingAddress: address,
              formData,
            }),
          }
        );

        if (!orderResponse.ok) {
          const errorData = await orderResponse.json();
          throw new Error(errorData.message || 'Failed to place order');
        }

        const { session } = await orderResponse.json();
        if (session && session.url) {
          window.location.href = session.url;
        } else {
          throw new Error('Checkout session URL not found in response');
        }
      } else {
        const orderResponse = await fetch(`${BASE_URL}/orders/create-order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            products,
            totalQuantity: cart.reduce((total, item) => total + item.quantity, 0),
            totalAmount: total,
            shippingAddress: address,
            formData,
          }),
        });

        if (!orderResponse.ok) {
          const errorData = await orderResponse.json();
          throw new Error(errorData.message || 'Failed to place order');
        }
        toast.success('Your order was placed successfully!');
        navigate('/order-place-success');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className='p-6 flex flex-col items-center justify-center min-h-[100vh] px-6'>
      <h2 className='text-2xl font-bold mb-4 pt-5'>Billing Details</h2>
      <div className='flex flex-col gap-5 md:flex-row w-full max-w-4xl pt-3'>
        {/* Billing details form */}
        <div className='w-full md:w-1/2 space-y-4'>
          <input
            type='text'
            placeholder='Full Name'
            className='border p-2 w-full'
            name='name'
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='Street Address'
            className='border p-2 w-full'
            name='address.street'
            value={formData.address.street}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='City'
            className='border p-2 w-full'
            name='address.city'
            value={formData.address.city}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='State'
            className='border p-2 w-full'
            name='address.state'
            value={formData.address.state}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='Zip Code'
            className='border p-2 w-full'
            name='address.zipCode'
            value={formData.address.zipCode}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='Country'
            className='border p-2 w-full'
            name='address.country'
            value={formData.address.country}
            onChange={handleInputChange}
          />
          <input
            type='text'
            placeholder='Phone Number'
            className='border p-2 w-full'
            name='phone'
            value={formData.phone}
            onChange={handleInputChange}
          />
          <input
            type='email'
            placeholder='Email Address'
            className='border p-2 w-full'
            name='email'
            value={formData.email}
            onChange={handleInputChange}
            readOnly
          />
          <div>
            <input
              type='checkbox'
              id='save-info'
              className='mr-2'
            />
            <label htmlFor='save-info'>
              Save this information for faster check-out next time
            </label>
          </div>
        </div>

        {/* Order summary */}
        <div className='w-full md:w-1/2 p-4 border rounded-lg'>
          {cart.map((item, index) => (
            <div
              key={index}
              className='flex justify-between items-center mb-2'
            >
              <img
                src={item.product.images[0]}
                alt={item.product.name}
                className='w-16 h-16 object-contain'
              />
              <div className='text-right mb-2'>
                <p>{item.product.name}</p>
                <p>
                  {item.quantity} x ₣ {item.product.price.toFixed(2)}
                </p>
              </div>
            </div>
          ))}
          <div className='flex justify-between items-center mt-4'>
            <p>Subtotal:</p>
            <p className='text-gray-600'>₣ {subtotal.toFixed(2)}</p>
          </div>
          <div className='flex justify-between items-center mt-2'>
            <p>Shipping:</p>
            <p className='text-gray-600'>
              {shipping === 0 ? 'Free' : shipping}
            </p>
          </div>
          <div className='flex justify-between items-center mt-4 font-bold'>
            <p>Total:</p>
            <p>₣ {total.toFixed(2)}</p>
          </div>

          {/* Payment options */}
          <div className='mt-4'>
            <div className='flex items-center'>
              <input
                type='radio'
                id='bank'
                name='paymentMethod'
                value={'bank'}
                checked={paymentMethod === 'bank'}
                onChange={handlePaymentChange}
                className='mr-2'
              />
              <label htmlFor='bank'>Bank</label>
            </div>
            <div className='flex items-center'>
              <input
                type='radio'
                id='cash'
                name='paymentMethod'
                value={'cash'}
                checked={paymentMethod === 'cash'}
                onChange={handlePaymentChange}
                className='mr-2'
              />
              <label htmlFor='cash'>Cash on delivery</label>
            </div>
          </div>

          {/* Coupon */}
          {/* <div className='flex mt-4'>
            <input
              type='text'
              placeholder='Coupon Code'
              className='border p-2 flex-1'
            />
            <button className='bg-primary text-white px-4 py-2'>
              Apply Coupon
            </button>
          </div> */}

          <button
            className='bg-primary hover:bg-red-600 text-white px-4 py-2 mt-4 w-full'
            onClick={handleSubmit}
          >
            {paymentMethod === 'bank' ? 'Pay and Place Order' : 'Place Order'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
