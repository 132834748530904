import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../config';
import { toast } from 'react-toastify';
import Loader from '../../components/Loaders/Loader';

function Cancels() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Orders
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${BASE_URL}/orders/my-orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const result = await response.json();

      if (response.ok) {
        const cancelOrders = result.filter(
          (order) => order.orderStatus === 'cancelled'
        );
        setOrders(cancelOrders);
        setLoading(false);
      } else {
        throw new Error('Failed to fetch orders');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Delete
  const handleDelete = async (orderId) => {
    try {
      const response = await fetch(`${BASE_URL}/orders/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        toast.success('Ordered Product deleted successfully!');
        await fetchOrders();
      } else {
        throw new Error('Failed to delete product from order');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className='flex flex-col pt-12'>
      {orders.length > 0 ? (
        <div>
          <h1 className='text-3xl font-bold mb-6 text-center'>Cancel Orders</h1>
          {loading ? (
            <Loader />
          ) : (
            <table className='w-full table-auto border-collapse bg-white rounded-lg shadow-md'>
              {/* Table Head */}
              <thead>
                <tr className='bg-gray-200 text-gray-800 border-b border-gray-300'>
                  <th className='px-6 py-3 w-[10%] text-center text-sm font-medium uppercase tracking-wider'>
                    Order ID
                  </th>
                  <th className='px-6 py-3 w-[10%] text-center text-sm font-medium uppercase tracking-wider hidden md:table-cell'>
                    Products
                  </th>
                  <th className='px-12 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider hidden md:table-cell'>
                    Order Date
                  </th>
                  <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider'>
                    Order Status
                  </th>
                  <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider'>
                    Delete Order
                  </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {orders.map((order) => (
                  <React.Fragment key={order._id}>
                    <tr className='border-b border-gray-300 hover:bg-gray-100'>
                      <td className='px-6 py-2 w-[10%] text-center'>
                        {order._id}
                      </td>
                      <td className='px-12 py-2 w-[10%] hidden md:table-cell'>
                        {order.products.map((product) => (
                          <p
                            key={product.product._id}
                            className='flex items-center justify-between'
                          >
                            <span>{product.product.name}</span>
                            <span className=''>{product.quantity}</span>
                          </p>
                        ))}
                      </td>
                      <td className='py-2 px-4 w-[10%] hidden md:table-cell'>
                        {new Date(order.createdAt).toLocaleString()}
                      </td>
                      <td className='py-2 px-8 w-[10%]'>{order.orderStatus}</td>
                      <td className='py-2 px-8 w-[10%]'>
                        <button
                          className='bg-red-500 hover:bg-red-700 text-white inline-block py-1 px-2 w-[100px] text-center font-bold rounded'
                          onClick={() => handleDelete(order._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <h1 className='text-3xl font-bold mb-6 text-center'>
          No Cancelled Orders Found
        </h1>
      )}
    </div>
  );
}

export default Cancels;
