import React from 'react';

const Terms = () => {
  return (
    <div className='flex justify-center items-center pt-32 mb-12'>
      <div>
        <div className='text-justify flex flex-col w-[800px] px-6 text-gray-500'>
          <h1 className='text-2xl font-bold text-center mb-4'>
            Terms and Conditions
          </h1>
          {/* General Terms and Conditions */}
          <div className=' text-justify text-sm'>
            <p className='mb-4'>General Terms and Conditions for Customers</p>
            <p className=''>
              The present 'General Terms and Conditions for Customers' are
              applicable to the relations between JUST EAT and the Customers.
              Restaurants are referred to the 'General Terms and Conditions for
              Restaurants'.
            </p>
          </div>

          {/* 1. Definitions */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300  py-2'>
              1. Definitions
            </h2>
            <div className='mb-4 ml-4 flex flex-col gap-2 text-sm'>
              <p>
                <span className='font-bold'>Offer: </span> the range of products
                and services offered by the Restaurant that can be ordered by
                the Customer through the Platform.
              </p>
              <p>
                {' '}
                <span className='font-bold'>Order: </span> an order placed by
                the Customer with the Restaurant through the Platform as regards
                the Offer selected by the Customer.{' '}
              </p>
              <p>
                <span className='font-bold'> Customer: </span>a natural person
                or legal entity placing an Order through the Platform.
              </p>
              <p>
                {' '}
                <span className='font-bold'>Agreement: </span> an agreement
                between the Customer and the Restaurant regarding an Order and
                the delivery or collection of the Order.
              </p>
              <p>
                <span className='font-bold'> Platform: </span> the website(s),
                apps, tools and other equipment of JUST EAT and its affiliated
                companies and business partners on which the Service is made
                available.
              </p>{' '}
              <p>
                <span className='font-bold'>Restaurant: </span> a company that
                prepares and sells meals, beverages and related articles and
                uses the Platform for the establishment and payment of the
                Agreements.
              </p>{' '}
              <p>
                <span className='font-bold'>Restaurant information: </span> the
                information about the Restaurant with regard to, among other
                things, company and contact information, general information,
                product range (meals, side dishes, options and beverages),
                prices for each individual product (including VAT), company
                logo, graphics, delivery area (including postal codes), delivery
                costs and minimum order amounts.
              </p>{' '}
              <p>
                <span className='font-bold'>Service: </span> the commercial
                services and/or activities that are offered to the Customer by
                JUST EAT, including publication of the Offer, facilitation of
                the conclusion of Agreements and transmission of Orders to the
                relevant Restaurant.
              </p>{' '}
              <p>
                <span className='font-bold'>JUST EAT: </span> EAT.ch GmbH acting
                for itself and on behalf of any corporate entity or person that
                controls, are controlled by or under the common control,
                directly or indirectly, with Takeaway.com Group B.V.
              </p>{' '}
              <p>
                <span className='font-bold'>Tip: </span> voluntary amount paid
                by a Customer intended for the courier delivering the Order.
              </p>
            </div>
          </div>

          {/* 2. Identity of JUST EAT */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              2. Identity of JUST EAT
            </h2>
            <div className='ml-4'>
              <div className='mb-2  text-sm'>
                <p>EAT.ch GmbH</p>
                <p>Werdstrasse 21</p>
                <p>8004 Zürich</p>
              </div>
              <div className=' text-sm'>
                <p>Tel: 0800 553 082</p>
                <p>
                  Email :
                  <a
                    className='underline text-black'
                    href='mailto:info@just-eat.ch'
                  >
                    info@just-eat.ch
                  </a>
                </p>
                <p>
                  <span> Company number: </span> CHE-114.486.017
                </p>
              </div>
            </div>
          </div>

          {/* 3. Applicability */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              3. Applicability
            </h2>
            <div className=' ml-4 flex flex-col gap-2  text-sm'>
              <p>
                1. The present General Terms and Conditions for Customers are
                only applicable to the Service. JUST EAT is not responsible for
                the Offer. As the case may be, the general terms and conditions
                of the Restaurant shall be applicable to the Offer in addition.
              </p>
              <p>
                2. By placing an Order the Customer directly concludes an
                Agreement with the Restaurant for delivery of the Offer selected
                by the Customer. The Customer is bound by the Order and will not
                be entitled to a refund except in case of cancellation allowed
                by the Restaurant pursuant to article 6 below.
              </p>
            </div>
          </div>

          {/* 4. The Offer */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              4. The Offer
            </h2>
            <div className='mb-4 ml-4 flex flex-col gap-2  text-sm'>
              <p>
                1. JUST EAT publishes the Offer on behalf of the Restaurants on
                the Platform, in accordance with the Restaurant information
                supplied by the Restaurants. JUST EAT shall not accept any
                responsibility or liability for the contents of the Offer and
                the Restaurant information on the Platform. The Restaurant may
                use ingredients and additives of meals and drinks, that could
                cause allergies and intolerances. If a Customer is allergic to
                any foodstuffs, we advise to contact the Restaurant by telephone
                for current allergen information before placing an Order.
              </p>
              <p>
                2. JUST EAT presents all Restaurant information in such a way
                that it is clear to the Customer what his rights and obligations
                are after having accepted the Offer.
              </p>
              <p>
                3. JUST EAT shall not accept any liability for Platform
                availability.
              </p>
            </div>
          </div>

          {/* 5. The Agreement */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              5. The Agreement
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                1. The Agreement is effective as from the moment the Customer
                finalizes the Order by clicking the 'Buy now' button during the
                process of placing an Order through the Platform.
              </p>
              <p>
                2. After receipt of the Order, JUST EAT will electronically
                confirm the Order to the Customer.
              </p>
              <p>
                3. The Agreement can only be executed by the Restaurant if the
                Customer provides correct and complete contact and address
                information when placing the Order. The Customer is obliged to
                immediately report any inaccuracies in the payment data supplied
                or mentioned to JUST EAT or the Restaurant.
              </p>
              <p>
                4. With respect to information on the status of his Order, after
                having placed the Order the Customer is required to be available
                by telephone or email (as indicated when placing the Order) for
                both the Restaurant and JUST EAT.
              </p>
              <p>
                5. If the Customer decides to have the Order delivered, the
                Customer must be present at the delivery address indicated by
                the Customer in order to take receive the Order items.
              </p>
              <p>
                6. If JUST EAT delivers the Order on behalf of the Restaurant,
                JUST EAT may charge the Customer delivery costs. The current
                delivery costs are stated on the Platform before a customer
                places an Order. A receipt for these delivery costs can be
                requested with JUST EAT.
              </p>
              <p>
                7. If the Customer decides to collect the Order, he should be
                present at the selected time at the collection location of the
                Restaurant, as indicated in the confirmation email, text message
                or on the JUST EAT website.
              </p>
              <p>
                8. Upon delivery of the Order, the Restaurant could ask for
                identification if the Order contains alcoholic products or other
                products with an age limit. If the Customer cannot identify
                himself adequately or does not meet the minimum age
                requirements, the Restaurant will refuse to deliver the relevant
                products to the Customer. In this case, cancellation costs may
                be charged
              </p>
              <p>
                9. EAT does not accept any liability relating to the execution
                of the Agreement.
              </p>
              <p>
                10. After placing the Order, the Customer can upon his sole
                discretion choose to tip a courier via the available online
                payment methods.
              </p>
              <p>
                11. The Tip is intended for the couriers and cannot be
                considered as payment for services of JUST EAT, JUST EAT will
                thereby only act as a trustee and transferor of the tip amounts.
              </p>
              <p>
                12. JUST EAT will transfer the Tip to the couriers in case those
                are directly engaged via JUST EAT. In case a courier is not
                engaged via JUST EAT but directly by the Restaurant, JUST EAT
                transfers the Tip to the Restaurant and obliges the Restaurant
                to pay-out the Tip to the courier. JUST EAT cannot guarantee or
                be held responsible for the transfer of the Tip from the
                Restaurant to the courier.
              </p>
              <p>
                13. After the Customer has received a confirmation of placing
                the Tip, the Tip cannot be refunded or returned.
              </p>
            </div>
          </div>

          {/* 6. Dissolution of the Agreement and cancellation of the Order */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              6. Dissolution of the Agreement and cancellation of the Order
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                1. Considering the perishable nature of the Offer, the Customer
                shall not be entitled to dissolve the Agreement. Orders cannot
                be cancelled by the Customer with JUST EAT. Cancellation of the
                Order with the Restaurant is only possible by the Customer if
                the Restaurant explicitly indicates that Cancellation of the
                Order by the Customer is possible.
              </p>
              <p>
                2. The Restaurant is entitled to cancel the Order, e.g., if the
                Offer is no longer available, if the Customer has provided an
                incorrect or inoperative telephone number or other contact
                information, or in case of force majeure.
              </p>
              <p>
                3. If the Customer places a false Order (for example by
                providing incorrect contact information, by not paying or by not
                being present on the delivery or collection location in order to
                receive the Order) or otherwise fails to comply with his
                obligations pursuant to the Agreement, JUST EAT shall be
                entitled to refuse any future Orders from such Customer.
              </p>
              <p>
                4. JUST EAT is entitled to refuse Orders and cancel Agreements
                on behalf of the Restaurant, if there is reasonable doubt about
                the correctness or authenticity of the Order or contact
                information. If the Customer places Orders that appear as false
                or fraudulent, JUST EAT may report this to the police.
              </p>
            </div>
          </div>

          {/* 7. Payment */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              7. Payment
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                1. At the moment the Agreement is concluded in accordance with
                the provisions of article 5.1 of these General Terms and
                Conditions for Customers, Customer is obliged to pay the
                Restaurant for the Order. The Customer may fulfil this payment
                obligation by using an online payment method through the
                Platform or by payment to the Restaurant at the door or at the
                collection location.
              </p>
              <p>
                2. An additional service fee for the services of JUST EAT in
                connection with processing the Order and customer services, are
                not covered by the price of the product and will be added to the
                total price.
              </p>
              <p>
                3. Subject to the provisions of article 6 of these General Terms
                and Conditions for Customers, the (partial) reimbursement of an
                online payment shall only be possible if the Order cannot be
                delivered (entirely). The reimbursement shall always be made
                into the same account as the one from which the payment has been
                made. Depending on the payment method used by the Customer, the
                processing of the reimbursement will take a maximum of 10
                working days.
              </p>
              <p>
                4. The Restaurant has authorized JUST EAT to accept the
                Customer&apos;s online payment on behalf of the Restaurant.
              </p>
            </div>
          </div>

          {/* 8. Complaints settlement */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              8. Complaints settlement
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                1. Complaints from the Customer about the Offer, the Order or
                the performance of the Agreement, are to be filed with for
                receipt by the Restaurant. The sole responsibility for the Offer
                of the Restaurant and the performance of the Agreement lies with
                the Restaurant. JUST EAT may only assume a mediating role.
              </p>
              <p>
                2. If the Customer has a complaint as regards the Service, the
                complaint is to be communicated by means of the contact form, by
                email or ordinary mail to the JUST EAT customer service, at the
                contact address as indicated in article 2 of the present General
                Terms and Conditions for Customers.
              </p>
              <p>
                3. Once the complaint has been received by JUST EAT, the latter
                shall react as soon as possible, but not later than within one
                week, with a confirmation of receipt. JUST EAT aims to handle
                the complaint as soon as possible, but not later than within two
                weeks.
              </p>
              <p>
                4. Complaints as described in sections 1 and 2 of this article
                must be filed promptly within due time after the Customer has
                detected the shortcomings, be fully and clearly described and
                filed with the respective Restaurant (section 1) or JUST EAT
                (section 2).
              </p>
            </div>
          </div>

          {/* 9. Newsletter */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              9. Newsletter
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                1. When placing the Order, the Customer may also subscribe to
                the newsletter. The Customer can unsubscribe via the link in the
                email or by contacting the customer service by using the contact
                data as mentioned under 'Correspondence Address' in article 2 of
                these General Terms and Conditions for Customers.
              </p>
            </div>
          </div>

          {/* 10. Inspection and correction of stored personal data */}
          <div>
            <h2 className='text-xl font-bold mt-6 mb-4 border-b border-gray-300 py-2'>
              10. Inspection and correction of stored personal data
            </h2>
            <div className='list-decimal flex flex-col gap-2 ml-4 text-sm'>
              <p>
                JUST EAT shall process personal data relating to the Customer.
                The processing of personal data is subject to the{' '}
                <a
                  className='underline text-black'
                  href='/privacy'
                >
                  Privacy Statement.
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Download button for terms pdf */}
        <a
          href='/terms-and-conditions.pdf'
          download='terms-and-conditions.pdf'
        >
          <button className='bg-primary text-white font-bold ml-8 py-2 px-4 rounded mt-4'>
            Download PDF
          </button>
        </a>
      </div>
    </div>
  );
};

export default Terms;
