import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';

const Products = ({ searchTerm }) => {
  const navigate = useNavigate();
  const { data: products } = FetchData(`${BASE_URL}/products`);

  if (!products) {
    return null;
  }

  const sortedProducts = products
    .filter((product) => product.deals === 'no' || product.deals === 'yes')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const displayedProducts = sortedProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewAll = () => {
    navigate('/view-all', { state: { dealType: 'no' } });
  };

  return (
    <>
      {displayedProducts.length > 0 ? (
        <div>
          <div className='font-bold md:text-[24px] flex justify-between pt-12'>
            <p>New Products</p>
            <div
              className='flex items-center gap-2 cursor-pointer'
              onClick={handleViewAll}
            >
              <p className='lg:text-[24px]'>View All</p>
              <i
                className='fa fa-arrow-right'
                aria-hidden='true'
              ></i>
            </div>
          </div>
          <div className='flex md:flex-wrap overflow-x-auto md:overflow-x-visible md:gap-4'>
            {displayedProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className='text-center pt-12 text-4xl text-primary h-[50vh] flex justify-center items-center gap-6'>
          <i className='fa fa-search'></i>
          <p className='text-lg'>
            No products found. Please try another search..
          </p>
        </div>
      )}
    </>
  );
};

export default Products;
