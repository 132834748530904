import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import MainRoutes from '../routes/Routers';

const Layout = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isUserRoute = location.pathname.startsWith('/my-account');

  // Search Term
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  const AccountRotes = isAdminRoute || isUserRoute;

  // Scroll to top when location (URL) changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);
  return (
    <div>
      {!AccountRotes && <Header onSearch={handleSearch} />}
      <main>
        <MainRoutes searchTerm={searchTerm} />
      </main>
      {!AccountRotes && <Footer />}
    </div>
  );
};

export default Layout;
