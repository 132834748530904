import React, { useState, useEffect } from 'react';
import { BASE_URL, token } from '../../config';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import CartEmpty from '../../assets/images/no cart.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loaders/Loader';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Cart() {
  const [cart, setCart] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  // fetch cart
  const fetchCart = async () => {
    try {
      const response = await fetch(`${BASE_URL}/cart`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart');
      }

      const data = await response.json();
      setCart(data);
    } catch (error) {
      toast.error('Failed to fetch cart');
      setError(error);
    }
  };

  // remove item
  const removeItem = async () => {
    if (!productToDelete) return; // If there's no product to delete, return early

    try {
      const response = await fetch(`${BASE_URL}/cart/${productToDelete}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to remove item');
      }

      await fetchCart();
      toast.success('Item removed successfully');
      closeModal(); // Close the modal after removing the item
    } catch (error) {
      toast.error(error.message);
    }
  };

  // updateQuantity cart
  const updateQuantity = async (productId, quantity) => {
    try {
      const response = await fetch(`${BASE_URL}/cart/${productId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update quantity');
      }

      toast.success('Quantity updated successfully');

      await fetchCart();
    } catch (error) {
      toast.error(error.message);
    }
  };

  //

  const openModal = (productId) => {
    setProductToDelete(productId); // Store the product ID to be deleted
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setProductToDelete(null); // Clear the product ID
  };

  useEffect(() => {
    fetchCart();
  }, []);

  if (!cart || !Array.isArray(cart.items) || cart.items.length === 0) {
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='flex flex-col justify-center h-[100vh] w-[100%] items-center'>
            <div className='lg:w-[30%] md:w[40%] w-[100%] '>
              <img
                src={CartEmpty}
                alt='Empty cart'
                className='w-full h-full'
              />
            </div>
            <p className='text-lg font-semibold flex gap-4 pt-10 flex-col justify-center items-center'>
              Your Cart is empty
              <span className='text-primary underline'>
                <Link to='/'>Click To Continue with Liquor House</Link>
              </span>
            </p>
          </div>
        )}
      </>
    );
  }

  if (error) {
    return <p>Error loading cart: {error.message}</p>;
  }

  const totalAmount = cart.items.reduce(
    (total, item) => total + (item.product.price * item.quantity || 0),
    0
  );

  const handleBuyNow = () => {
    localStorage.setItem('cart', JSON.stringify(cart));
    navigate('/checkout', { state: { cart: cart.items } });
  };

  // clear cart
  const clearCart = async () => {
    localStorage.removeItem('cart');
    try {
      const response = await fetch(`${BASE_URL}/cart`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to clear cart');
      }

      // Re-fetch the cart to reflect the empty cart state
      await fetchCart();
      toast.success('Cart cleared successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className='container mx-auto pt-[250px] p-4 min-h-screen h-[100vh] overflow-y-scroll'
      style={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {/*  Table */}
      <table className=' bg-white rounded-lg shadow-md mb-4 hidden sm:flex sm:flex-col '>
        <thead>
          <tr className='flex justify-between'>
            <th className='p-4 w-[60%] text-left'>Item & Description</th>
            <th className='p-4 w-[60%] text-center'>Qty</th>
            <th className='p-4 w-[60%] text-center'>Rate</th>
            <th className='p-4 w-[60%] text-center'>Amount</th>
            <th className='p-4 w-[60%]'>Remove</th>
          </tr>
        </thead>
        <tbody>
          {cart.items.map((item) => (
            <tr
              key={item.product._id}
              className='border-t border-gray-200 flex justify-between'
            >
              <td className='p-4 w-[60%]'>
                <div className='flex items-center'>
                  <img
                    src={item.product.images && item.product.images[0]}
                    alt={item.product.name}
                    className='w-12 h-12 object-cover rounded-full mr-4'
                  />
                  <span className='text-lg font-medium overflow-hidden sm:hidden md:block '>
                    {item.product.name}
                  </span>
                </div>
              </td>
              <td className='p-4 w-[60%] text-center'>
                <div className='flex items-center justify-center space-x-2'>
                  <button
                    onClick={() =>
                      updateQuantity(item.product._id, item.quantity - 1)
                    }
                    className='w-8 h-8 flex justify-center items-center bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 rounded'
                  >
                    –
                  </button>
                  <span className='w-8 h-8 flex justify-center items-center text-lg'>
                    {item.quantity}
                  </span>
                  <button
                    onClick={() =>
                      updateQuantity(item.product._id, item.quantity + 1)
                    }
                    className='w-8 h-8 flex justify-center items-center bg-primary text-white hover:bg-primary rounded'
                  >
                    +
                  </button>
                </div>
              </td>
              <td className='p-4 w-[60%] text-center'>
                ₣ {item.product.price}
              </td>
              <td className='p-4 w-[60%] text-center'>
                ₣ {item.product.price * item.quantity}
              </td>
              <td className='p-4 w-[60%] text-center'>
                <button
                  className='text-red-500 hover:text-red-700'
                  onClick={() => openModal(item.product._id)} // Open modal on click
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* In mobile view */}
      <div className='sm:hidden block'>
        {cart.items.map((item) => (
          <div className='flex justify-around items-center border-b border-gray-400 py-2'>
            <div className='flex flex-col gap-6'>
              <img
                src={item.product.images[0]}
                alt={item.product.name}
                className='w-12  object-cover rounded-full'
              />
            </div>
            <div className='flex gap-6'>
              <div className='flex flex-col gap-2'>
                <p className='text-right'>{item.product.name}</p>
                <p className='text-right'>₣ {item.product.price}</p>
                <div className='flex items-center justify-center space-x-2'>
                  <button
                    onClick={() =>
                      updateQuantity(item.product._id, item.quantity - 1)
                    }
                    className='w-8 h-8 flex justify-center items-center bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 rounded'
                  >
                    –
                  </button>
                  <span className='w-8 h-8 flex justify-center items-center text-lg'>
                    {item.quantity}
                  </span>
                  <button
                    onClick={() =>
                      updateQuantity(item.product._id, item.quantity + 1)
                    }
                    className='w-8 h-8 flex justify-center items-center bg-primary text-white hover:bg-primary rounded'
                  >
                    +
                  </button>
                </div>
              </div>
              <button
                className='bg-primary px-6 text-white rounded-md hover:bg-white hover:text-primary transition-all duration-300'
                onClick={() => openModal(item.product._id)} // Open modal on click
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Bottom Row */}
      <div className='bg-white p-4 flex justify-around sm:justify-between items-center rounded-lg mt-4'>
        <div className='text-lg font-semibold'>Total: ₣ {totalAmount}</div>

        <div className='flex justify-between gap-4'>
          <div>
            <button
              className='px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary'
              onClick={clearCart}
            >
              Clear All
            </button>
          </div>
          <div>
            {cart.items.length > 0 && (
              <button
                className='px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary'
                onClick={handleBuyNow}
              >
                Buy Now
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='bg-white p-6 rounded shadow-lg max-w-sm mx-auto'>
          {productToDelete && (
            <>
              <div className='flex items-center mb-4'>
                <img
                  src={
                    cart.items.find(
                      (item) => item.product._id === productToDelete
                    )?.product.images[0] || '/path/to/default/image.jpg'
                  }
                  alt='Product'
                  className='w-16 h-16 rounded-full mr-4'
                />
                <p className='text-lg font-semibold'>
                  {
                    cart.items.find(
                      (item) => item.product._id === productToDelete
                    )?.product.name
                  }
                </p>
              </div>
              <p className='mb-4'>
                Are you sure want to delete this item from your cart?
              </p>
              <div className='flex justify-between'>
                <button
                  onClick={removeItem}
                  className='bg-red-500 text-white px-4 py-2 rounded'
                >
                  Delete
                </button>
                <button
                  onClick={closeModal}
                  className='bg-gray-500 text-white px-4 py-2 rounded'
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Cart;
