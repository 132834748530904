import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../config';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';

const Related = () => {
  const { id } = useParams();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const {
    data: product,
    isLoading,
    error,
  } = FetchData(`${BASE_URL}/products/${id}`);

  // Ensure product data is loaded before destructuring
  const { images, name, rating, category } = product || {};

  useEffect(() => {
    if (product) {
      const fetchRelatedProducts = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/products?category=${product.category}`
          );
          const data = await response.json();

          setRelatedProducts(
            data.filter(
              (item) =>
                item.id !== product._id && item.category === product.category
            )
          );
        } catch (error) {
          console.error('Error fetching related products:', error);
        }
      };

      fetchRelatedProducts();
    }
  }, [product]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className='p-4 pt-20'>
      <h2 className='font-bold text-xl mb-4'>Selected Product</h2>
      {product && (
        <div className='mb-8'>
          <div className='p-4 rounded-lg shadow-lg flex flex-col w-full sm:w-auto relative'>
            <div className='p-2 lg:w-full h-[120px] lg:h-[150px] md:w-full md:h-[150px] bg-black rounded-t-lg overflow-hidden flex items-center justify-center'>
              <img
                src={images}
                alt={name}
                className='w-full h-full object-contain'
              />
            </div>
            <div className='mt-4 text-center lg:text-lg text-[14px] md:text-lg'>
              <p className='text-xs sm:text-lg text-gray-600 mt-1'>
                {category}
              </p>
              <p className='text-xs sm:text-sm text-gray-600 mt-1'>
                {product.description}
              </p>
              <p className='text-xs sm:text-lg text-gray-600 mt-1'>{rating}</p>
              <p className='text-xs sm:text-lg text-gray-600 mt-1'>
                Available: {product.stock}
              </p>
            </div>
          </div>
        </div>
      )}

      <h2 className='font-bold text-2xl md:text-2xl text-[20px] mb-4'>
        Related Products
      </h2>
      <div className='flex flex-wrap justify-center gap-6'>
        {relatedProducts.map((product) => (
          <ProductCard
            key={product._id}
            product={product}
          />
        ))}
      </div>
    </div>
  );
};

export default Related;
