import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';

const Products = ({ searchTerm }) => {
  const navigate = useNavigate();
  const { data } = FetchData(`${BASE_URL}/products`);

  const products = data;

  if (!products) {
    return null;
  }

  const sortedProducts = products
    .filter((product) => product.deals === 'yes')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const displayedProducts = sortedProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewAll = () => {
    navigate('/view-all', { state: { dealType: 'yes' } });
  };

  return (
    <>
      {displayedProducts.length > 0 ? (
        <div>
          <div className='font-bold md:text-[24px] flex justify-between pt-12'>
            <p>Deals</p>
            <div
              className='flex items-center gap-2 cursor-pointer'
              onClick={handleViewAll}
            >
              <p className='lg:text-[24px]'>View All</p>
              <i
                className='fa fa-arrow-right'
                aria-hidden='true'
              ></i>
            </div>
          </div>
          <div className='flex md:flex-wrap overflow-x-auto md:overflow-x-visible md:gap-4'>
            {displayedProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className='font-bold md:text-[24px] flex justify-between pt-12'>
          <p></p>
        </div>
      )}
    </>
  );
};

export default Products;
