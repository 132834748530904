import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL, token } from '../../config';

const ProductCard = ({ product, displayMode }) => {
  const { _id, images, name, price, discount, rating, deals, category } =
    product;

  const numericPrice = parseFloat(price) || 0;
  const numericDiscount = parseFloat(discount) || 0;

  const calculateDiscountPrice = (price, discount) => {
    return discount > 0
      ? (price - (price * discount) / 100).toFixed(2)
      : price.toFixed(2);
  };

  const discount_price = calculateDiscountPrice(numericPrice, numericDiscount);

  const [formData, setFormData] = useState({
    productId: _id,
    quantity: 1, // default quantity to 1
  });

  const handleAddToCart = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${BASE_URL}/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // send the token for authentication
        },
        body: JSON.stringify({
          product: formData.productId,
          quantity: formData.quantity,
        }),
      });

      const { message } = await res.json();
      if (!res.ok) throw new Error(message);
      toast.success('Product added to cart!');
    } catch (error) {
      toast.error('Something went wrong: ' + error.message);
    }
  };

  const handleWishlistSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${BASE_URL}/wishlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const { message } = await res.json();
      if (!res.ok) throw new Error(message);
      toast.success('Product added to wishlist!');

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: null,
      }));
    } catch (error) {
      toast.error('Something went wrong: ' + error.message);
    }
  };

  return (
    <div>
      {displayMode === 'simple' ? (
        <div>
          <div className='p-2 w-full h-[120px] md:w-[200px] md:h-[150px] bg-black rounded-lg overflow-hidden'>
            <img
              src={images}
              alt={name}
              className='w-full h-full object-contain'
            />
          </div>
          <div>
            <p className='text-xs sm:text-lg text-gray-600'>{category}</p>
          </div>
        </div>
      ) : (
        <div className=' flex gap-4 py-5 sm:flex-row lg:flex-col lg:items-start'>
          <div className='p-2 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex flex-col cursor-pointer w-[150px] md:w-auto relative'>
            {numericDiscount > 0 && (
              <div className='absolute top-1 left-1 bg-primary text-white text-xs px-2 py-1 rounded-tr-md rounded-bl-md'>
                {numericDiscount}%
              </div>
            )}
            <div className='lg:h-[220px] lg:w-[180px]'>
              <Link to={`/products/${_id}`}>
                <div className='p-2 lg:w-[180px] h-[120px] md:w-[200px] md:h-[150px] bg-black rounded-t-lg overflow-hidden flex items-center justify-center'>
                  <img
                    src={images}
                    alt={name}
                    className='w-full h-full object-contain'
                  />
                </div>
                <div>
                  {deals === 'yes' ? (
                    <div className='mt-1'>
                      <p className=''>{name}</p>
                      <div className='flex gap-2 items-center mt-2'>
                        <p className='bg-primary text-white text-[10px] sm:text-[10px] md:text-base px-2 py-1 rounded-tr-md rounded-bl-md'>
                          CHF {discount_price}
                        </p>
                        {numericDiscount > 0 && (
                          <p className='line-through text-[10px] sm:text-[10px] md:text-base text-gray-600'>
                            {numericPrice.toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='flex flex-col gap-2 mt-1'>
                      <p className=''>{name}</p>
                      <p className=' text-gray-600 font-semibold text-[12px] sm:text-[14px] md:text-base'>
                        CHF {price}
                      </p>
                      <p className=' text-gray-600 '>{rating}</p>
                    </div>
                  )}
                </div>
              </Link>
            </div>

            <div className='flex justify-between lg:mt-1 mt-3 w-full text-[20px] sm:text-[25px] px-2'>
              <button
                className='text-gray-800 hover:text-primary'
                aria-label='Add to wishlist'
                onClick={handleWishlistSubmit}
              >
                <i className='fa-regular fa-heart'></i>
              </button>
              <button
                className='text-gray-800 hover:text-primary'
                aria-label='Add to cart'
                onClick={handleAddToCart} // Add onClick event to trigger cart addition
              >
                <i className='fa-solid fa-plus'></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
