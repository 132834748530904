import React, { useState, useEffect } from 'react';
import { BASE_URL, token } from '../../config';
import { toast } from 'react-toastify';
import CartEmpty from '../../assets/images/EmptyWishlist.png';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loaders/Loader';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  // Fetch wishlist from the backend
  const fetchWishlist = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wishlist`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch wishlist');
      }

      const data = await response.json();
      setWishlist(data.products); // Populate wishlist with products
    } catch (error) {
      toast.error('Failed to fetch Wishlist');
      setError(error);
    } 
  };

  const handleAddToCart = async (productId) => {
    try {
      // First, add the product to the cart
      const res = await fetch(`${BASE_URL}/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product: productId,
          quantity: 1, // Default quantity is 1
        }),
      });

      const { message } = await res.json();
      if (!res.ok) throw new Error(message);

      toast.success('Product added to cart!');

      // After successfully adding to cart, remove the product from the wishlist
      const removeRes = await fetch(`${BASE_URL}/wishlist/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!removeRes.ok) {
        const errorData = await removeRes.json();
        throw new Error(
          errorData.message || 'Failed to remove item from wishlist'
        );
      }

      // Update the wishlist state by filtering out the product
      setWishlist((prevWishlist) =>
        prevWishlist.filter((product) => product._id !== productId)
      );
    } catch (error) {
      toast.error('Something went wrong: ' + error.message);
    }
  };

  // Remove item from wishlist
  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await fetch(
        `${BASE_URL}/wishlist/${productToDelete._id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to remove item');
      }

      const updatedWishlist = await response.json();
      setWishlist(updatedWishlist.products);
      toast.success('Item removed successfully');
    } catch (error) {
      toast.error(error.message);
      console.error('Error removing item:', error);
    } finally {
      closeModal(); // Close the modal after deleting
    }
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  if (!wishlist.length) {
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='flex flex-col justify-center lg:h-[90vh] md:h-[100vh] h-[80vh]  w-[100%] items-center'>
            <div className='lg:w-[30%] md:w[40%] w-[100%] '>
              <img
                src={CartEmpty}
                alt='Empty cart'
                className='w-full h-full'
              />
            </div>
            <p className='text-lg font-semibold flex gap-4 pt-10 flex-col justify-center items-center'>
              Your Wishlist is empty
              <span className='text-primary underline'>
                <Link to='/'>Click To Continue with Liquor House</Link>
              </span>
            </p>
          </div>
        )}
      </>
    );
  }

  if (error) {
    return <p>Error loading wishlist: {error.message}</p>;
  }

  const openModal = (product) => {
    setProductToDelete(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProductToDelete(null);
  };

  const handleClearWishlist = async () => {
    try {
      const res = await fetch(`${BASE_URL}/wishlist`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || 'Failed to clear wishlist');
      }

      const data = await res.json(); // Ensure you handle response data properly
      toast.success(data.message || 'Wishlist cleared successfully');
      setWishlist([]); // Clear wishlist in the frontend as well
    } catch (error) {
      console.error('Error clearing wishlist:', error.message);
      toast.error(error.message || 'Failed to clear wishlist');
    }
  };

  return (
    <div className='p-6 min-h-[100vh] pt-40 flex flex-col'>
      <div className='flex justify-between px-4 mb-4'>
        <div>
          <h2 className='text-xl font-semibold text-black mb-4 text-left'>
            My Wishlist ({wishlist.length})
          </h2>
        </div>
        <div>
          <button
            onClick={handleClearWishlist}
            className='px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary'
          >
            Clear All
          </button>
        </div>
      </div>

      <div className='flex flex-wrap px-2 lg:gap-4 items-center md:gap-8 gap-2'>
        {wishlist.map((item) => (
          <div
            key={item._id}
            className='relative text-center items-center border p-4 rounded-lg shadow-md'
          >
            <button
              className='absolute top-0 right-0 text-red-500 mt-2 mr-2'
              onClick={() => openModal(item)}
            >
              <i className='fas fa-trash'></i>
            </button>

            <div className='p-2 lg:w-[180px] h-[120px] lg:h-[150px] md:w-[150px] md:h-[150px] overflow-hidden flex items-center justify-center'>
              <img
                src={item.images || 'https://via.placeholder.com/150'}
                alt={item.name}
                className='w-full h-full object-contain'
              />
            </div>

            <div className='pt-4'>
              <button
                className='bg-primary text-white py-1 px-2 rounded'
                onClick={() => handleAddToCart(item._id)} // Add to Cart button
              >
                Add To Cart
              </button>
              <div className='pt-4'>
                <p>{item.name}</p>
                <p>{item.price}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='bg-white p-6 rounded shadow-lg max-w-sm mx-auto'>
          {productToDelete && (
            <>
              <div className='flex items-center mb-4'>
                <img
                  src={productToDelete.images}
                  alt={productToDelete.name}
                  className='w-16 h-16 rounded-full mr-4'
                />
                <p className='text-lg font-semibold'>{productToDelete.name}</p>
              </div>
              <p className='mb-4'>Are you sure you want to delete this item?</p>
              <div className='flex justify-between'>
                <button
                  onClick={handleDelete}
                  className='bg-red-500 text-white px-4 py-2 rounded'
                >
                  Delete
                </button>
                <button
                  onClick={closeModal}
                  className='bg-gray-500 text-white px-4 py-2 rounded'
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Wishlist;
