import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../config';
import { toast } from 'react-toastify';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [activeOrders, setActiveOrders] = useState([]);

  useEffect(() => {
    const filteredOrders = orders.filter(
      (order) => order.orderStatus !== 'cancelled'
    );
    setActiveOrders(filteredOrders);
  }, [orders]);

  // Fetch Cart
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${BASE_URL}/orders/my-orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const result = await response.json();

      if (response.ok) {
        // Sort orders by creation date in descending order
        const sortedOrders = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setOrders(sortedOrders);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Update Status
  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to update the status of order #${orderId} to "${status}"?`
        )
      )
        return;
      const response = await fetch(`${BASE_URL}/orders/${orderId}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ orderId, status }),
      });
      const result = await response.json();

      await fetchOrders();

      if (response.ok) {
        toast.success(`Order ${status} successfully`);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Call Fetch Cart Function
  useEffect(() => {
    fetchOrders();
  }, []);

  const openModal = (order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  if (loading) {
    return <p>Loading your orders...</p>;
  }

  return (
    <div className='flex flex-col pt-12'>
      <h1 className='text-3xl font-bold mb-6 text-center'>Orders</h1>
      {activeOrders.length > 0 ? (
        <table className='w-full table-auto border-collapse bg-white shadow-md'>
          {/* Table Head */}
          <thead>
            <tr className='bg-gray-200 text-gray-800 border-b border-gray-300'>
              <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider '>
                Order
              </th>
              <th className='px-6 py-3 w-[10%] text-center text-sm font-medium uppercase tracking-wider hidden md:table-cell'>
                Products
              </th>
              <th className='px-12 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider hidden md:table-cell'>
                Total
              </th>
              <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider'>
                Order Status
              </th>
              <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider hidden md:table-cell'>
                Payment Status
              </th>
              <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider'>
                Actions
              </th>
              <th className='px-6 py-3 w-[10%] text-left text-sm font-medium uppercase tracking-wider'>
                Details
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {activeOrders.map((order) => (
              <React.Fragment key={order._id}>
                <tr className='border-b border-gray-300 hover:bg-gray-100'>
                  <td className='px-4 py-2 w-[10%] '># {order._id}</td>
                  <td className='px-12 py-2 w-[10%] hidden md:table-cell'>
                    {order.products.map((product) => (
                      <p
                        key={product.product._id}
                        className='flex items-center justify-between'
                      >
                        <span>{product.product.name}</span>
                        <span className=''>{product.quantity}</span>
                      </p>
                    ))}
                  </td>
                  <td className='px-12 py-2 w-[10%] text-left hidden md:table-cell'>
                    {order.totalQuantity} = {order.totalAmount}.00
                  </td>
                  <td className='px-6 py-2 w-[10%]'>
                    <span
                      className={`inline-block py-1 px-2 w-[100px] text-center  rounded text-white ${
                        order.orderStatus === 'pending'
                          ? 'bg-red-500'
                          : order.orderStatus === 'shipped'
                          ? 'bg-orange-500'
                          : 'bg-green-500'
                      }`}
                    >
                      {order.orderStatus.charAt(0).toUpperCase() +
                        order.orderStatus.slice(1)}
                    </span>
                  </td>
                  <td className='px-8 py-2 w-[10%] hidden md:table-cell'>
                    <span
                      className={`inline-block py-1 px-2 w-[100px] text-center rounded text-white ${
                        order.paymentStatus === 'pending'
                          ? 'bg-red-500'
                          : 'bg-green-500'
                      }`}
                    >
                      {order.paymentStatus}
                    </span>
                  </td>
                  <td className='px-4 py-2 w-[10%]'>
                    <button
                      className={`inline-block py-1 px-2 w-[100px] text-center  rounded text-white ${
                        order.orderStatus === 'pending'
                          ? 'bg-red-500'
                          : 'bg-green-500'
                      }`}
                      onClick={() =>
                        handleUpdateOrderStatus(
                          order._id,
                          order.orderStatus === 'pending'
                            ? 'cancelled'
                            : 'delivered'
                        )
                      }
                    >
                      {order.orderStatus === 'pending' ? 'Cancel' : 'Confirm'}
                    </button>
                  </td>
                  <td className='px-4 py-2 w-[10%]'>
                    <button
                      className='hover:text-black text-gray-800 px-4'
                      onClick={() => openModal(order)}
                    >
                      <i className='fas fa-eye'></i>
                    </button>
                  </td>
                </tr>

                {/* Modal */}
                {selectedOrder === order && (
                  <div className='fixed inset-0 flex items-center justify-center z-50'>
                    <div
                      className='absolute inset-0 bg-black opacity-50'
                      onClick={closeModal}
                    ></div>
                    <div className='bg-white p-6 rounded-lg z-10 shadow-lg'>
                      <div className='flex justify-between mb-4'>
                        <h2 className='text-lg font-bold'>Order Details</h2>
                        <button
                          className='text-gray-600 hover:text-gray-800 text-[20px]'
                          onClick={closeModal}
                        >
                          <i className='fa-solid fa-xmark'></i>
                        </button>
                      </div>
                      <p>
                        <strong>Order Date:</strong>{' '}
                        {new Date(order.createdAt).toLocaleString()}
                      </p>
                      <table className='min-w-full mt-4'>
                        <tbody>
                          {order.products.map((product) => (
                            <tr key={product.product._id}>
                              <td>
                                <img
                                  src={product.product.images}
                                  alt={product.product.name}
                                  className='w-10 h-10 rounded-full mr-3 object-contain bg-gray-200'
                                />
                              </td>
                              <td>
                                {product.product.name} - {product.quantity} - ₣{' '}
                                {product.product.price}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              colSpan='2'
                              className='text-center text-lg pt-6'
                            >
                              <strong>Total: ₣ {order.totalAmount}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>You don't have any orders yet.</p>
      )}
    </div>
  );
}

export default Orders;
