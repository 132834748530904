import { Link } from 'react-router-dom';
import { authContext } from '../../context/authContext.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { FaTh } from 'react-icons/fa';
import Logo from '../../assets/images/logos.png';

function Header({ onSearch }) {
  const [showLinks, setShowLinks] = useState(false);
  const { user, role, token } = useContext(authContext); // Keep role as you need it for navigation
  const { dispatch } = useContext(authContext);
  const navigate = useNavigate();

  // Get user image
  const [formData, setFormData] = useState({
    name: user?.name || '',
    image: user?.image || null,
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name || '',
        image: user?.image || null,
      });
    }
  }, [user]);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const handleLinkClick = () => {
    setShowLinks(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (showLinks) {
        setShowLinks(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showLinks]);

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    setShowLinks(false);
    navigate('/');
    toast.success('Logged out successfully');
    window.location.reload();
  };

  const handleDashboardNavigation = () => {
    if (role === 'customer') {
      navigate('/my-account');
    } else if (role === 'admin') {
      navigate('/admin');
    } else {
      navigate('/home');
    }
    handleLinkClick();
  };
  const viewAllNavigate = () => {
    navigate('/view-all', { state: { dealType: 'no' } });
  };

  // Search
  const handleInputChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div>
      <div className='main'>
        <div className='fixed top-0 z-50 w-full navbar flex justify-center h-[40px] bg-black shadow-gray-50 text-white md:text-base sm:text-sm text-[10px] px-2 '>
          {/* News */}
          <div className='flex justify-between items-center gap-4'>
            <p className='text-center md:text-left'>
              Summer Sale For All Food Items And Free Express Delivery - OFF
              50%!
            </p>
            <button
              onClick={viewAllNavigate}
              className='bg-black text-white'
            >
              {' '}
              Shop Now{' '}
            </button>
            <select className='bg-black text-white border-none'>
              <option>English</option>
              <option>Tamil</option>
            </select>
          </div>
        </div>
        <div className='header fixed z-10 w-full h-[120px] bg-white shadow-md px-4 pt-14'>
          <div className='search flex items-center justify-between gap-3'>
            <div className='w-20'>
              <Link to='/'>
                <img
                  src={Logo}
                  alt='logo'
                  className='w-full h-full object-cover'
                />
              </Link>
            </div>

            <div className='rounded-md px-4 bg-gray-100 label md:w-[90%] w-[60%] border-none items-center flex justify-between scroll mr-2'>
              <i className='fa-solid fa-magnifying-glass'></i>
              <input
                type='search'
                placeholder='Search Product'
                onChange={handleInputChange}
                className='px-4 bg-transparent focus:outline-none md:w-[100%] h-[45px] items-center'
              />
            </div>

            {/* Show mobile menu icon only before login */}
            {user === null && (
              <div className='md:hidden block text-[35px] justify-end '>
                <i
                  className='fa-solid fa-list cursor-pointer'
                  onClick={toggleLinks}
                ></i>
              </div>
            )}

            <div className='flex items-center gap-4'>
              <div className='shop md:text-[25px] hidden gap-12 md:gap-5 md:flex'>
                <Link to='/wishlist'>
                  <i className='fa-regular fa-heart cursor-pointer'></i>
                </Link>
                <Link to='/category'>
                  <FaTh className='md:h-[35px]' />
                </Link>
                <Link to='/cart'>
                  <i className='fa-solid fa-cart-shopping cursor-pointer'></i>
                </Link>
              </div>

              <div>
                {token && user ? (
                  <div className='user text-black'>
                    <div
                      onClick={toggleLinks}
                      className='cursor-pointer w-[40px] h-[40px] overflow-hidden rounded-full '
                    >
                      <img
                        src={
                          formData.image ||
                          'http://www.gravatar.com/avatar/?d=mp'
                        }
                        alt='profile'
                        className='w-full h-full object-cover rounded-full'
                      />
                    </div>
                  </div>
                ) : (
                  <Link to='/login'>
                    <button className='bg-primary px-6 py-2 text-white font-semibold rounded-md hidden md:block'>
                      Login
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>

          {user === null && showLinks && (
            <div className='dropdown-links flex flex-col gap-3 mt-2 bg-black text-white p-4 rounded shadow-md absolute top-[93%] right-0 '>
              <Link
                to='/wishlist'
                onClick={handleLinkClick}
              >
                Wishlist
              </Link>
              <Link
                to='/category'
                onClick={handleLinkClick}
              >
                All Categories
              </Link>
              <Link
                to='/cart'
                onClick={handleLinkClick}
              >
                Cart
              </Link>
              <Link
                to='/login'
                onClick={handleLinkClick}
              >
                <button className='bg-primary px-6 py-2 text-white font-semibold rounded-md'>
                  Login
                </button>
              </Link>
            </div>
          )}

          {user && showLinks && (
            <div className='dropdown-links flex flex-col gap-3 mt-2 bg-black text-white p-4 rounded shadow-md absolute top-[93%] right-0 '>
              <button
                className='pr-5'
                onClick={handleDashboardNavigation}
              >
                My-Account
              </button>
              <Link
                to='/wishlist'
                onClick={handleLinkClick}
                className='md:hidden'
              >
                Wishlist
              </Link>
              <Link
                to='/category'
                onClick={handleLinkClick}
                className='md:hidden'
              >
                All Categories
              </Link>
              <Link
                to='/cart'
                onClick={handleLinkClick}
                className='md:hidden'
              >
                Cart
              </Link>
              <button
                className='bg-primary px-6 py-2 text-white font-semibold rounded-md'
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
