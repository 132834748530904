import Photo from "../Productimage/thumbnail (1).jpg";
import Photo1 from '../Productimage/thumbnail (2).jpg'
import Photo3 from '../Productimage/thumbnail (4).jpg'
import Photo6 from "../Productimage/thumbnail6 (1).jpg"
import Photo8 from '../Productimage/thumbnail6 (3).jpg';
import Photo9 from "../Productimage/thumbnail6 (4).jpg"

const imageData = [
  Photo,
  Photo1,
  Photo3,
  Photo6,
  Photo8,
  Photo9,
];

export default imageData;