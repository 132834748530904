import React from 'react';
import { Link } from 'react-router-dom';
import { token } from '../../config';
import LOGO from '../../assets/images/logos.png';
import {
  FaUser,
  FaLock,
  FaShoppingCart,
  FaMapMarkerAlt,
  FaCity,
  FaMapPin,
} from 'react-icons/fa';

function Footer() {
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    window.location.reload();
  };

  return (
    <div className='bottom-0 border-t-4 bg-gray-200'>
      {/* Logo */}
      <div className='grid grid-cols-2 sm:flex sm:flex-wrap gap-4 justify-around sm:px-12 px-[7px] py-4'>
        <Link to='/'>
          <img
            src={LOGO}
            alt='logo'
            className='w-[200px] object-cover'
          />
        </Link>
        {/* Account */}
        <div className='account flex flex-col'>
          <h1 className='font-bold text-xl md:text-lg mb-2 text-left sm:text-center'>
            Account
          </h1>
          <div className='links flex-col flex gap-3  text-sm md:text-base'>
            <Link
              to='/my-account'
              className='flex items-center gap-2'
            >
              <FaUser /> My Account
            </Link>

            {token ? (
              <Link
                to='/'
                className='flex items-center gap-2'
                onClick={logout}
              >
                <FaLock /> Logout
              </Link>
            ) : (
              <Link
                to='/login'
                className='flex items-center gap-2'
              >
                <FaLock /> Login
              </Link>
            )}

            <Link
              to='/cart'
              className='flex items-center gap-2'
            >
              <FaShoppingCart /> Cart
            </Link>
          </div>
        </div>

        {/* Support */}
        <div className='support flex flex-col'>
          <h1 className='font-bold text-xl md:text-lg  mb-2 text-left sm:text-center'>
            Support
          </h1>
          <div className='links flex flex-col gap-3  text-sm md:text-base'>
            <Link
              to='/address'
              className='flex items-center gap-2'
            >
              <FaMapMarkerAlt /> 643 Badenerstrasse
            </Link>
            <Link
              to='/city'
              className='flex items-center gap-2'
            >
              <FaCity /> Zurich
            </Link>
            <Link
              to='/zip'
              className='flex items-center gap-2'
            >
              <FaMapPin /> 8048
            </Link>
          </div>
        </div>

        {/* Quick Links */}
        <div className='quick-links flex flex-col'>
          <h1 className='font-bold text-xl md:text-lg  mb-2 text-left sm:text-center'>
            Quick Links
          </h1>
          <div className='links flex flex-col gap-3  text-sm md:text-base'>
            <Link
              to='/privacy'
              className='flex items-center gap-2'
            >
              <FaLock /> Privacy Policy
            </Link>
            <Link
              to='/terms'
              className='flex items-center gap-2'
            >
              <FaLock /> Terms Of Use
            </Link>
            <Link
              to='/contact'
              className='flex items-center gap-2'
            >
              <FaMapMarkerAlt /> Contact
            </Link>
          </div>
        </div>
      </div>
      <div className='copyrights bg-black text-white py-1 text-xs md:text-sm'>
        <p className='text-center'>
          &copy; Copyright NIH 2022. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
