import Carousal from './product/ProductCarousal.jsx';
import Products from './product/Products';
import OfferCard from './product/OfferCard.jsx';
import Categories from './product/categories.jsx';

function Home({ searchTerm }) {
  return (
    <div>
      <Carousal />
      <div className='px-8'>
        <OfferCard searchTerm={searchTerm} />
        <Products searchTerm={searchTerm} />
        <Categories />
      </div>
    </div>
  );
}

export default Home;
