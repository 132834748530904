import React from 'react';

function privacy() {
  return (
    <div className='flex flex-col justify-center items-center pt-32 mb-12'>
      <h1 className='text-3xl font-bold text-center'>Privacy Policy</h1>
      <div className='text-justify flex flex-col w-[800px] px-6 text-gray-500 text-sm'>
        <p className='mt-4'>
          Swiss Liquor is committed to protecting the privacy of our customers
          and visitors to our website. This privacy policy outlines the types of
          personal information we collect and how we use it. We also provide
          information on how we protect your personal information and your
          rights in relation to it.
        </p>
        <p className='mt-4'>
          Personal information is collected when you register an account with
          us, place an order, or contact us. This information may include, but
          is not limited to, your name, email address, phone number, and home
          address.
        </p>
        <p className='mt-4'>
          We use your personal information to process your orders, to contact
          you regarding your orders, to provide customer service, and to improve
          our website and services. We may also use your personal information to
          inform you of special offers, promotions, and new products if you have
          opted-in to receive such information.
        </p>
        <p className='mt-4'>
          We will not sell, rent, or share your personal information with any
          third party without your consent. We may share your personal
          information with our trusted partners who assist us in providing our
          services, such as our payment provider and delivery companies. We
          require these partners to maintain the confidentiality and security of
          your personal information and to use it only for the purpose of
          providing their services to us.
        </p>
        <p className='mt-4'>
          We maintain physical, electronic, and procedural safeguards to protect
          your personal information from unauthorized access, modification, or
          disclosure. We use secure servers to store your personal information
          and encrypt your data when transmitting it over the internet.
        </p>
        <p className='mt-4'>
          You have the right to request access to your personal information, to
          request that we correct or delete your personal information, and to
          opt-out of receiving promotional communications from us. If you would
          like to exercise any of these rights, please contact us at
          <a href='mailto:info@just-eat.ch' className='underline text-black'> info@just-eat.ch</a>.
        </p>
        <p className='mt-4'>
          By using our website or services, you consent to the collection and
          use of your personal information as outlined in this privacy policy.
          We reserve the right to modify this privacy policy at any time without
          notice. If we make any material changes to this policy, we will post a
          notification on our website and/or send an email to our registered
          users. Your continued use of our website or services after we post or
          send a notice will constitute your acceptance of the changes.
        </p>
      </div>
    </div>
  );
}

export default privacy;
