import React, { useState, useEffect, useRef } from "react";
import FetchData from "../../hooks/fetchData";
import { toast } from "react-toastify";
import { BASE_URL, token } from "../../config";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ProductTable = () => {
  const [visibleDetails, setVisibleDetails] = useState(null);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const { data: productData } = FetchData(`${BASE_URL}/products`);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All"); // Category state
  const [editingProductId, setEditingProductId] = useState(null);
  const [updatedCategory, setUpdatedCategory] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (productData) {
      setData(productData);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [productData]);

  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await fetch(
        `${BASE_URL}/products/${productToDelete._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete product");
      }

      toast.success("Product deleted successfully!");
      setData((prevData) =>
        prevData.filter((product) => product._id !== productToDelete._id)
      );
      closeModal();
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  const openModal = (product) => {
    setProductToDelete(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProductToDelete(null);
  };

  const toggleDetails = (id) => {
    setVisibleDetails(visibleDetails === id ? null : id);
  };

  const handleScroll = () => setVisibleDetails(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Get unique categories from the product list
  const uniqueCategories = ["All", ...new Set(data.map((product) => product.category))];

  // Filter data by category and search
  const filteredData = data.filter((product) => {
    const isMatchCategory =
      selectedCategory === "All" || product.category === selectedCategory;
    const isMatchSearch =
      product.name.toLowerCase().includes(search.toLowerCase()) ||
      product.category.toLowerCase().includes(search.toLowerCase());

    return isMatchCategory && isMatchSearch;
  });

  const isNotFound = filteredData.length === 0 && search.trim() !== "";
  const handleEditClick = (product) => {
    setEditingProductId(product._id);
    setUpdatedCategory(product.category); // Set initial category value for editing
  };

  const handleCategoryChange = (e) => {
    setUpdatedCategory(e.target.value);
  };

  const handleCategoryUpdate = async (productId) => {
    try {
      const updatedProduct = { category: updatedCategory };

      const response = await fetch(`${BASE_URL}/products/${productId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedProduct),
      });

      if (!response.ok) {
        throw new Error("Failed to update category");
      }

      setData((prevData) =>
        prevData.map((product) =>
          product._id === productId
            ? { ...product, category: updatedCategory }
            : product
        )
      );

      toast.success("Category updated successfully!");
      setEditingProductId(null); // Exit edit mode
    } catch (error) {
      toast.error("Failed to update category: " + error.message);
    }
  };

  return (
    <div className='overflow-x-auto w-full'>
      {/* Category filter buttons */}
      <div className='mb-5 flex space-x-2 justify-center'>
        {uniqueCategories.map((category) => (
          <button
            key={`${category}_${Math.random().toString(36).substr(2, 9)}`}
            onClick={() => setSelectedCategory(category)}
            className={`py-2 px-4 rounded-lg ${
              selectedCategory === category
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-black'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Search bar */}
      <div className='rounded-md px-4 bg-white label lg:w-[100%] md:w-[70%] border-none items-center flex justify-between mb-5'>
        <i className='fa-solid fa-magnifying-glass'></i>
        <input
          ref={inputRef}
          type='search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search by Product Name or Category'
          className='px-4 bg-transparent focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] items-center'
        />
      </div>

      {isNotFound ? (
        <div className='flex justify-center items-center h-32'>
          <p className='text-red-500 text-lg font-bold'>No products found</p>
        </div>
      ) : (
        <div className='min-w-full bg-white flex items-center justify-center'>
          <div className='w-full'>
            <div className='overflow-x-auto shadow-md rounded my-6'>
              <table className='min-w-full table-auto'>
                <thead>
                  <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
                    <th className='py-3 px-6 text-left'>Product</th>
                    <th className='py-3 px-6 text-left hidden md:table-cell'>
                      Stock
                    </th>
                    <th className='py-3 px-6 text-left hidden md:table-cell'>
                      Quantity
                    </th>
                    <th className='py-3 px-6 text-left'>
                      Category
                    </th>
                    <th className='py-3 px-6 text-center'>Action</th>
                  </tr>
                </thead>
                <tbody className='text-black font-bold text-md'>
                  {filteredData.map((product, index) => (
                    <React.Fragment key={product._id}>
                      <tr
                        className={`border-b border-gray-200 hover:bg-gray-100 ${
                          index % 2 ? 'bg-gray-50 ' : ''
                        }`}
                      >
                        <td className='py-3 px-6 text-left flex items-center'>
                          <img
                            src={product.images}
                            alt={product.name}
                            className='w-10 h-10 rounded-full mr-3 object-contain bg-black'
                          />
                          <span>{product.name}</span>
                        </td>
                        <td className='py-3 px-6 text-left hidden md:table-cell'>
                          {product.stock > 5 ? (
                            <span className='bg-green-100 text-green-700 px-2 py-1 rounded'>
                              In Stock
                            </span>
                          ) : (
                            <span className='bg-red-100 text-red-700 px-2 py-1 rounded'>
                              Low Stock
                            </span>
                          )}
                        </td>
                        <td className='py-3 px-6 text-left hidden md:table-cell'>
                          {product.stock || 'NO '}
                        </td>
                        <td className='py-3 px-6 text-left'>
                          {editingProductId === product._id ? (
                            <input
                              type='text'
                              value={updatedCategory}
                              onChange={handleCategoryChange}
                              className='px-2 py-1 border rounded'
                            />
                          ) : (
                            <span>{product.category}</span>
                          )}
                        </td>
                        <td className='p-4 text-center'>
                          {editingProductId === product._id ? (
                            <button
                              onClick={() => handleCategoryUpdate(product._id)}
                              className='text-green-500'
                            >
                              Save
                            </button>
                          ) : (
                            <div className='flex justify-center space-x-2'>
                              <button
                                className='text-black lg:hidden'
                                onClick={() => toggleDetails(product._id)}
                              >
                                <i className='fas fa-eye'></i>
                              </button>
                              <button
                                className='text-black'
                                onClick={() => handleEditClick(product)}
                              >
                                <i className='fas fa-edit'></i>
                              </button>
                              <button
                                className='text-red-500'
                                onClick={() => openModal(product)}
                              >
                                <i className='fas fa-trash'></i>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                      {visibleDetails === product._id && (
                        <tr className='bg-gray-100'>
                          <td
                            colSpan='4'
                            className='py-3 px-6'
                          >
                            <div className='flex flex-col md:hidden'>
                              <p className='font-semibold'>
                                Stock:{' '}
                                <span
                                  className={`bg-${
                                    product.stock > 0 ? 'green' : 'red'
                                  }-200 text-${
                                    product.stock > 0 ? 'green' : 'red'
                                  }-600 py-1 px-3 rounded-full text-xs`}
                                >
                                  {product.stock > 0
                                    ? 'In stock'
                                    : 'Out of stock'}
                                </span>
                              </p>
                              <p className='font-semibold'>
                                Category: {product.category || 'No category'}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
              overlayClassName='fixed inset-0 bg-black bg-opacity-50'
            >
              <div className='bg-white p-6 rounded shadow-lg max-w-sm mx-auto'>
                {productToDelete && (
                  <>
                    <div className='flex items-center mb-4'>
                      <img
                        src={productToDelete.images}
                        alt={productToDelete.name}
                        className='w-16 h-16 rounded-full  mr-4'
                      />
                      <p className='text-lg font-semibold'>
                        {productToDelete.name}
                      </p>
                    </div>
                    <p className='mb-4'>
                      Are you sure want to delete this product?
                    </p>
                    <div className='flex justify-between'>
                      <button
                        onClick={handleDelete}
                        className='bg-red-500 text-white px-4 py-2 rounded'
                      >
                        Delete
                      </button>
                      <button
                        onClick={closeModal}
                        className='bg-gray-500 text-white px-4 py-2 rounded'
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductTable;
