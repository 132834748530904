import React, { useEffect, useState } from 'react';
import { BASE_URL, token } from '../../config';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [stocks, setStocks] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [lastOrders, setLastOrders] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [lastCustomers, setLastCustomers] = useState([]);

  const navigate = useNavigate();

  // Get all
  useEffect(() => {
    // Fetch Products
    const fetchProducts = async () => {
      try {
        const res = await fetch(`${BASE_URL}/products`);
        const data = await res.json();

        setProducts(data);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch Customers
    const fetchCustomers = async () => {
      try {
        const res = await fetch(`${BASE_URL}/users`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();

        setCustomers(data);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch Orders
    const fetchOrders = async () => {
      try {
        const res = await fetch(`${BASE_URL}/orders`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();

        setOrders(data);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch Top Selling Products
    const fetchTopProducts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/orders/top-selling-products`);
        const data = await response.json();
        setTopProducts(data);
      } catch (error) {
        console.error('Error fetching top-selling products:', error);
      }
    };

    // Call The Functions
    fetchOrders();
    fetchProducts();
    fetchCustomers();
    fetchTopProducts();
  }, [orders, totalPayments]);

  useEffect(() => {
    // Get last 5 orders
    const lastFiveOrders = orders
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 5);
    setLastOrders(lastFiveOrders);

    // Get last 5 customers
    const lastFiveCustomers = customers
      .filter((customer) => customer.role === 'customer')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 5);
    setLastCustomers(lastFiveCustomers);

    // Find Total Payments
    const calculateTotalPayments = (orders) => {
      const totalPayments = orders.reduce(
        (acc, order) => acc + order.totalAmount,
        0
      );
      setTotalPayments(totalPayments);
    };
    calculateTotalPayments(orders);

    // Filter Low Stock Only
    const inventory = products
      .filter((product) => product.stock)
      .sort((a, b) => a.stock - b.stock);

    setStocks(inventory);
  }, [customers, orders, products]);

  // Set Low Stocks
  const lowStockThreshold = 10;
  const lowStockItems = stocks.filter((item) => item.stock < lowStockThreshold);
  const nonLowStockItems = stocks.filter(
    (item) => item.stock >= lowStockThreshold
  );

  const handleViewAll = () => {
    navigate('/view-all', { state: { dealType: 'no' } });
  };

  return (
    <div className='bg-gray-100 p-4 sm:p-6'>
      {/* Summary Cards */}
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-4 sm:mb-6'>
        {/* Total products */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <div className='flex items-center'>
            <div className=' flex flex-col justify-center items-center w-full'>
              <h4 className='text-gray-600 text-sm sm:text-base'>
                Total Products
              </h4>
              <p className='text-xl sm:text-2xl font-bold text-gray-900'>
                {products.length}
              </p>
              <p className='text-green-500 text-sm'>
                {products.length > 0
                  ? ((products.length - products.length * 0.01) /
                      products.length) *
                    100
                  : 0}
                %↑
              </p>
            </div>
          </div>
        </div>

        {/* Total Orders */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center w-full'>
              <h4 className='text-gray-600 text-sm sm:text-base'>
                Total Orders
              </h4>
              <p className='text-xl sm:text-2xl font-bold text-gray-900'>
                {orders.length}
              </p>
              <p className='text-red-500 text-sm'>
                {orders.length > 0
                  ? ((orders.length - orders.length * 0.01) / orders.length) *
                    10
                  : 0}
                %↓
              </p>
            </div>
          </div>
        </div>

        {/* Total Payments */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center w-full'>
              <h4 className='text-gray-600 text-sm sm:text-base'>
                Orders Paid
              </h4>
              <p className='text-xl sm:text-2xl font-bold text-gray-900'>
                ₣ {totalPayments}.00
              </p>
              <p className='text-green-500 text-sm'>
                {totalPayments > 0
                  ? ((totalPayments - totalPayments * 0.012) / totalPayments) *
                    100
                  : 0}
                %↑
              </p>
            </div>
          </div>
        </div>

        {/* Total Users */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center w-full'>
              <h4 className='text-gray-600 text-sm sm:text-base'>
                Total Visitors
              </h4>
              <p className='text-xl sm:text-2xl font-bold text-gray-900'>
                {customers.length}
              </p>
              <p className='text-green-500 text-sm'>
                {customers.length > 0
                  ? ((customers.length - customers.length * 0.012) /
                      customers.length) *
                    100
                  : 0}
                %↑
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 pt-5'>
        {/* Top Selling Products & Orders */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <div className='flex justify-between items-center mb-3 sm:mb-4'>
            <h4 className='text-gray-700 font-semibold text-sm sm:text-base'>
              Top Selling Product
            </h4>
            <button
              className='text-blue-500 hover:text-blue-700 text-xs sm:text-sm'
              onClick={handleViewAll}
            >
              View All
            </button>
          </div>
          <div className=' h-[40vh] overflow-y-scroll'>
            <ul className='space-y-4'>
              {topProducts.map((item) => (
                <li
                  key={item.product._id}
                  className='bg-white shadow-md rounded-md p-4'
                >
                  <div className='flex items-center'>
                    <img
                      src={item.product.images[0]}
                      alt={item.product.name}
                      className='w-12 h-12 rounded-full mr-4'
                    />
                    <div>
                      <h3 className='text-lg font-semibold'>
                        {item.product.name}
                      </h3>
                      <p className='text-gray-500'>
                        Quantity Sold: {item.totalQuantity}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Last 5 Orders */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow '>
          <h4 className='text-gray-700 font-semibold text-sm sm:text-base'>
            Latest Orders
          </h4>
          <ul className='space-y-4  h-[40vh] overflow-y-scroll'>
            {lastOrders.map((order) => (
              <li
                key={order._id}
                className='bg-white shadow-md rounded-md p-4'
              >
                <div className='flex items-center'>
                  <img
                    src={order.products[0].product.images[0]}
                    alt={order.products[0].product.name}
                    className='w-12 h-12 rounded-full mr-4'
                  />
                  <div>
                    <h3 className='text-lg font-semibold'>
                      Order #{order._id}
                    </h3>
                    <p className='text-gray-500'>
                      {order.products[0].product.name} - Quantity:{' '}
                      {order.products[0].quantity}
                    </p>
                    <p className='text-gray-500'>
                      Total Amount: ₣ {order.totalAmount}
                    </p>
                    <p className='text-gray-500'>
                      Order Date: {new Date(order.createdAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Third Row */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 pt-5'>
        {/* Low Stock Items */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <h4 className='text-gray-700 font-semibold text-sm sm:text-base'>
            Low Stock ({lowStockItems.length})
          </h4>
          <ul className='space-y-4 h-[40vh] overflow-y-scroll'>
            {lowStockItems.map((item) => (
              <li
                key={item.name}
                className='bg-white shadow-md rounded-md p-4'
              >
                <div className='flex items-center justify-between'>
                  <div>
                    <h3 className='text-lg font-semibold'>{item.name}</h3>
                    <p className='text-sm text-red-500'>Stock: {item.stock}</p>
                  </div>
                  <span className='bg-red-100 text-red-700 px-2 py-1 rounded'>
                    Low Stock
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Non-Low Stock Items */}
        <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
          <h4 className='text-gray-700 font-semibold text-sm sm:text-base'>
            In Stock ({nonLowStockItems.length})
          </h4>
          <ul className='space-y-4 h-[40vh] overflow-y-scroll'>
            {nonLowStockItems.map((item) => (
              <li
                key={item.name}
                className='bg-white shadow-md rounded-md p-4'
              >
                <div className='flex items-center justify-between'>
                  <div>
                    <h3 className='text-lg font-semibold'>{item.name}</h3>
                    <p className='text-sm text-gray-500'>Stock: {item.stock}</p>
                  </div>
                  <span className='bg-green-100 text-green-700 px-2 py-1 rounded'>
                    In Stock
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Fourth Row */}
      {/* Last 5 Customers */}
      <div className='bg-white p-3 sm:p-4 rounded-lg shadow'>
        <h4 className='text-gray-700 font-semibold text-sm sm:text-base'>
          New Customer Sign-ups
        </h4>
        <ul className='flex justify-between'>
          {lastCustomers.map((customer) => (
            <li
              key={customer._id}
              className='bg-white shadow-md rounded-md p-4'
            >
              <div className='flex items-center'>
                <img
                  src={customer.image}
                  alt={customer.name}
                  className='w-12 h-12 rounded-full mr-4'
                />
                <div>
                  <h3 className='text-lg font-semibold'>{customer.name}</h3>
                  <p className='text-gray-500'>{customer.email}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
