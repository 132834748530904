import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';
import Loader from '../../components/Loaders/Loader';
import { BASE_URL } from '../../config';

const AllOffers = () => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const { data } = FetchData(`${BASE_URL}/products`);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const products = data;

  if (!products) {
    return null;
  }
  const dealType = location.state.dealType;

  const filteredProducts = products
    .sort((a, b) => (a.deals === 'yes' ? -1 : b.deals === 'yes' ? 1 : 0))
    .filter((product) => product.deals === dealType || dealType === 'no');

  const headingText = dealType === 'yes' ? 'All Offers' : 'All New Products';

  return (
    <div className='pt-32 px-8 max-h-[100vh] h-[100vh] overflow-y-auto'>
      <h1 className='text-2xl font-bold p-4'>{headingText}</h1>
      {loading ? (
        <Loader />
      ) : (
        <div className='flex flex-wrap gap-4'>
          {filteredProducts.map((product) => (
            <ProductCard
              key={product._id}
              product={product}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AllOffers;
