import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BASE_URL, token } from '../../config';

const CheckOutSuccess = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${BASE_URL}/orders/payment-success`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sessionId }),
        });

        if (!res.ok) throw new Error('Failed to update order status');

        if (res.ok) {
          await fetch(`${BASE_URL}/cart`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [sessionId]);

  return (
    <div className='container mx-auto flex flex-col justify-center items-center h-screen text-center'>
      <h1 className='text-4xl font-bold mb-4'>Order Confirmed</h1>
      <FaCheckCircle className='text-8xl text-green-500 mb-8' />
      <p className='text-xl mb-8'>Thank you for your purchase.</p>
      <Link
        to='/'
        className='bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 rounded'
      >
        <button>Back To Home</button>
      </Link>
    </div>
  );
};

export default CheckOutSuccess;
