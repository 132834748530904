import { token } from '../config';
import { authContext } from '../context/authContext';
import { useState, useEffect, useContext } from 'react';

const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useContext(authContext);
  const userId = user?._id || null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'user-id': userId,
          },
        });

        const result = await response.text(); // Use text() instead of json() for debugging
        // console.log('Response Result:', result); // Log the raw response for debug

        if (!response.ok) {
          throw new Error(result.message || 'Failed to fetch data');
        }

        setData(JSON.parse(result).data || JSON.parse(result));
      } catch (error) {
        setError(error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, userId]);

  return { data, loading, error };
};

export default useFetchData;
